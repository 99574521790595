import React, { useCallback, useEffect, useState } from 'react';
import searchBrowse from '@ingka/ssr-icon/paths/magnifying-glass-list';
import { Option, Select, InputField, Button } from 'src/skapa';
import shield from '@ingka/ssr-icon/paths/shield-checkmark';
import { IRRModal } from 'src/components/IRRModal';
import {
  CustomerInfo,
  EventType,
  RegistrySearchInput,
} from '../../generated/graphql';
import {
  SearchContent,
  SearchInputs,
  SearchButtonContainer,
  valueOrUndefined,
} from '.';
import { useRegistrySearchInput } from 'src/hooks/RegistrySearchInputProvider';
import { CustomerSearchModal } from './CustomerSearchModal';
import { useIntl, useT } from 'src/hooks/intl';
import { handleKeyboardEvent } from 'src/utils/keyboardEvents';
import { DateInputField } from './DateInputField';
import { Errors } from '../ErrorMessage';
import { useHistory, useLocation } from 'react-router';
import ClearableInputField from '../ClearableInputField';

export const RegistrySearch: React.FC = () => {
  const t = useT();
  const { country } = useIntl();
  const { search, loading, errorsProps, latestSearch, resetSearch } =
    useRegistrySearchInput();
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerInfo | undefined
  >(latestSearch?.customer || undefined);
  const [inputState, setInputState] = useState<RegistrySearchInput>(
    latestSearch?.searchInput || {}
  );
  const [findCustomerModalOpen, setFindCustomerModalOpen] =
    useState<boolean>(false);
  const hasCustomer = Boolean(selectedCustomer?.partyUId);
  const enabled =
    Object.values(inputState).some((el) => el !== undefined) || hasCustomer;

  const backToCollegeMarkets = ['us', 'pt', 'nl'];

  const { state } = useLocation();
  const { push } = useHistory();

  const reset = useCallback(() => {
    setSelectedCustomer(undefined);
    setInputState({});
    resetSearch();
  }, [resetSearch]);

  const [IRRModalOpen, setIRRModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const clearForm = (state as { clearRegistrySearch: boolean })
      ?.clearRegistrySearch;
    if (clearForm) {
      push('/registries');
      reset();
    }
  }, [state, reset, push]);

  const onRegistrySearch = (): void => {
    if (enabled) {
      const queryInput = {
        ...inputState,
        owner: selectedCustomer?.partyUId,
      };

      search(queryInput, selectedCustomer);
    }
  };

  const customerDisplayName = selectedCustomer
    ? `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
    : '';

  return (
    <>
      <SearchContent>
        <SearchInputs showAdvanced={true}>
          {errorsProps && <Errors {...errorsProps} />}
          <InputField
            disabled={loading}
            type="text"
            id="title"
            label={t('registrySearch.title')}
            value={inputState?.title || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputState({
                ...inputState,
                title: valueOrUndefined(e.target.value),
              });
            }}
            onKeyDown={handleKeyboardEvent(['enter'], onRegistrySearch)}
          />
          <Select
            disabled={loading}
            id="event-type"
            label={t('registrySearch.eventType')}
            value={inputState?.eventType || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setInputState({
                ...inputState,
                eventType: valueOrUndefined(e.target.value) as EventType,
              });
            }}
          >
            <Option value="WEDDING" name={t('event.WEDDING.type')} />
            <Option value="GRADUATION" name={t('event.GRADUATION.type')} />
            <Option value="BABY_SHOWER" name={t('event.BABY_SHOWER.type')} />
            <Option value="BIRTHDAY" name={t('event.BIRTHDAY.type')} />
            <Option value="HOUSEWARMING" name={t('event.HOUSEWARMING.type')} />
            {backToCollegeMarkets.includes(country.toLowerCase()) && (
              <Option
                value="BACK_TO_COLLEGE"
                name={t('event.BACK_TO_COLLEGE.type')}
              />
            )}
            <Option value="OTHER" name={t('event.OTHER.type')} />
          </Select>
          <DateInputField
            fieldName={t('registrySearch.eventDate')}
            value={inputState?.eventDate || null}
            setValue={(date: Date | null) => {
              setInputState({
                ...inputState,
                eventDate: date || undefined,
              });
            }}
            onKeyDown={onRegistrySearch}
            showInline={false}
          />
          <ClearableInputField
            id="search-customer"
            label={t('auditLogSearch.customer')}
            value={customerDisplayName}
            onClick={() => {
              setFindCustomerModalOpen(true);
            }}
            iconOnClick={() => {
              setFindCustomerModalOpen(true);
            }}
            onClear={() => {
              setSelectedCustomer(undefined);
            }}
            ssrIcon={searchBrowse}
          />
          <SearchButtonContainer showAdvanced={true}>
            <Button
              loading={loading}
              disabled={!enabled}
              fluid
              text={t('search.button')}
              type="primary"
              onClick={onRegistrySearch}
            />
            <Button
              disabled={!enabled}
              fluid
              text={t('reset')}
              type="secondary"
              onClick={reset}
            />
            {selectedCustomer?.partyUId && (
              <Button
                loading={loading}
                ssrIcon={shield}
                text={t('irr.export')}
                type="secondary"
                onClick={() => setIRRModalOpen(!IRRModalOpen)}
                fluid
              />
            )}
          </SearchButtonContainer>
        </SearchInputs>
      </SearchContent>
      <CustomerSearchModal
        open={findCustomerModalOpen}
        onClose={() => {
          setFindCustomerModalOpen(false);
        }}
        setSelectedCustomer={setSelectedCustomer}
        selectedCustomer={selectedCustomer}
      />
      {selectedCustomer?.partyUId && (
        <IRRModal
          open={IRRModalOpen}
          setOpen={setIRRModalOpen}
          partyUId={selectedCustomer?.partyUId}
        />
      )}
    </>
  );
};
